<template>
	<Section :options="sectionOptions" class="actualites">

		<div class="position-relative">

			<div class="timeline-border"></div>

			<!-- Posts
			============================================= -->
			<div id="posts" class="post-timeline row col-mb-50">

				<template v-for="(actualite, index) in actualites">

					<div v-if="showEntryDate(index)" class="entry entry-date-section col-12 mb-3 mt-0" :key="'entry-date'+index+'_'+currentLanguageCode">
						<span>{{ formatMonth(actualites[index].date) }}</span>
					</div>

					<div class="entry col-12 alt" :key="'entry'+index+'_'+currentLanguageCode">
						<div class="entry-timeline">
							<div class="timeline-divider"></div>
						</div>
						<div v-if="display == 'row'" class="grid-inner display-row row g-0">
							<div class="col-md-4">
								<div v-if="actualites[index].picture" class="entry-image">
									<router-link :to="getPostPath(actualites[index])">
										<img class="image_fade" :src="getStaticURLFromPath(actualites[index].picture.original.path)" :alt="getLocalizedText(actualites[index].title)">
									</router-link>
								</div>
							</div>
							<div class="col-md-8 ps-md-4">
								<div class="entry-title title-sm">
									<h2>
										<router-link :to="getPostPath(actualites[index])">
											{{ getLocalizedText(actualites[index].title) }}
										</router-link>
									</h2>
								</div>
								<div class="entry-meta">
									<ul>
										<li><i class="icon-calendar3"></i> {{ formatDate(actualites[index].date) }}</li>
										<li v-if="false" ><a href="blog-single.html#comments"><i class="icon-comments"></i> 13</a></li>
										<li v-if="false"><a href="#"><i class="icon-camera-retro"></i></a></li>
									</ul>
								</div>
								<div class="entry-content">
									<p>{{ getLocalizedText(actualites[index].shortDescription) }}</p>
									<router-link class="more-link" :to="getPostPath(actualites[index])">
										{{ $t('actualites.details') }}
									</router-link>
								</div>
							</div>
						</div>
						<div v-else class="grid-inner display-default">
							<div class="entry-title">
								<h2>
									<router-link :to="getPostPath(actualites[index])">
										{{ getLocalizedText(actualites[index].title) }}
									</router-link>
								</h2>
							</div>
							<div class="entry-meta">
								<ul>
									<li><i class="icon-calendar3"></i> {{ formatDate(actualites[index].date) }}</li>
									<li v-if="false" ><a href="blog-single.html#comments"><i class="icon-comments"></i> 13</a></li>
									<li v-if="false"><a href="#"><i class="icon-camera-retro"></i></a></li>
								</ul>
							</div>
							<div v-if="actualites[index].picture" class="entry-image">
								<router-link :to="getPostPath(actualites[index])">
									<img class="image_fade" :src="getStaticURLFromPath(actualites[index].picture.original.path)" :alt="getLocalizedText(actualites[index].title)">
								</router-link>
							</div>
							<div class="entry-content">
								<p>{{ getLocalizedText(actualites[index].shortDescription) }}</p>
								<router-link class="more-link" :to="getPostPath(actualites[index])">
									{{ $t('actualites.details') }}
								</router-link>
							</div>
						</div>
					</div>

				</template>

			</div><!-- #posts end -->

		</div>
	</Section>
</template>

<style>

@media (min-width: 992px) {
	/* We move the timeline border to the middle of the date entry */
	.actualites .timeline-border {
		left:125px;
		margin-left:0px;
	}

	/* We align the entry date section to the left */
	.actualites .entry.entry-date-section {
		text-align: left;
	}

	/* We center the text within the entry date */
	.actualites .entry.entry-date-section span {
		text-align: center;
	}

	/* We increase the space on the left to add entry date half size */
	.actualites .post-timeline .entry.alt:not(.entry-date-section) {
		/* standard margin + 125px + 85px */
		padding-left: calc((var(--bs-gutter-x) * .5) + 125px + 85px);
	}

	/* We set the position of the timeline link for each entry */
	.actualites .entry .entry-timeline {
		display: block;
		/* Middle of the title */
		top: 12px;
		/* standard margin + 125px - 6px (half size of bullet) */
		left: calc((var(--bs-gutter-x) * .5) + 125px - 6px);
	}

	/* We set custom size of the timeline divider */
	.actualites .entry .entry-timeline .timeline-divider {
		width: 60px;
	}
}

/* We set custom space between title and metas */
.actualites #posts .entry-meta {
	margin-top: 5px;
}

/* We set custom space between metas and picture */
.actualites #posts .display-default .entry-image {
	margin-top: 10px;
}

/* We ensure that entry image is not too big */
.actualites #posts .entry-image img {
	max-height: 400px;
	width:auto;
}

/* We reduce the space between post entries */
.actualites .post-timeline .entry {
	margin-bottom: 30px;
}


@media (max-width: 767.98px) {
	/* We reduce the space between content and post header */
	.actualites #posts .entry-content {
		margin-top: 20px;
	}

	/* We reduce the space after entry content */
	.actualites #posts .entry-content p {
		margin-bottom: 20px;
	}
}



</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, FormatDateType, formatMonth as genericFormatMonth } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getters as blogGetters } from '@fwk-client/modules/blog/stores';

/** @cmsOptions */
export interface TimelineOptions {
  /** @cmsType CmsText */
  feedName?:CmsText,
  /** @cmsType CmsText */
  actualitePageName?:CmsText,
  /** @cmsType CmsEnum */
  display?:CmsEnum<"default"|"row">,
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface TimelineLabels {
   
}

/** @cmsSlots */
export interface TimelineSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<TimelineOptions>,
        labels: {
          type: Object as PropType<TimelineLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<TimelineSlots>
    },
    components: {
        Section
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const store = useStore();

        const computedOptions:TimelineOptions = {
            sectionOptions : {section:false},
            actualitePageName:"actualite",
			display:"row",
            ...props.options
        };

		const actualites:Ref<any[]> = ref([]);
		actualites.value = [
			...blogGetters.$blog_posts.get.call(app).call(app, computedOptions.feedName)
		]

		const currentLanguageCode = computed(() => {
			return store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
		})

		/**
		 * showEntryDate
		 * We show entry date before first actualite or when month between two actualites is different.
		 */
		const showEntryDate = (index:number) => {
			return index == 0 || (new Date(actualites.value[index].date).getMonth() != new Date(actualites.value[index-1].date).getMonth())
		}

		const formatMonth = (date:Date) => {
			return genericFormatMonth(date, currentLanguageCode.value, {type:FormatDateType.LONG});
		}

		const formatDate = (date:Date) => {
			return formatDay(date, currentLanguageCode.value)
		}

		const getPostPath = (post:any) => {
			// We display product details page
			var languageCode = currentLanguageCode.value;
			return {
				name : computedOptions.actualitePageName,
				params : {
					lang : languageCode,
					postPath : post.path
				}
			}
		}

        return {
            sectionOptions:computedOptions.sectionOptions,
			display:computedOptions.display,
            actualites,
            showEntryDate,
			currentLanguageCode,
            formatMonth,
			formatDate,
			getPostPath
        }

    }
})
</script>