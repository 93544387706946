<template>
	<div class="page-actualites content">
		<h1 class="visually-hidden">{{ $t('actualites.title') }}</h1>

			<div class="content-wrap">

				<Timeline feedName="vigneron-online"></Timeline>

			</div>

	</div>
</template>

<style>

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Timeline from '../cms/canvas/blog/Timeline.vue';

@Component({
  components: {
	  Timeline
  }
})
export default class Actualites extends mixins(GenericPage) {

	
	
}
</script>